<template>
  <!--begin::Project Listing-->
  <div class="project-template" v-if="getPermission('project:view')">
    <PageHeaderCount
      moduleType="project"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_projects"
      countkey="project_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'project-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('project:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in projectMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="6" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_projects
                          }}</template>
                          <template v-else>{{
                            item.project_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="6" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('project:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="customerDialog = true"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="!isEngineerChannel()"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                  color="cyan white--text"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'project',
                        },
                      })
                    )
                  "
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips module="project"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Project"
          :basicSearchFields="[
            'barcode',
            'reference',
            /* 'soled_job_no', */
            'name',
            'attention',
            'tags',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 60vh; position: relative"
        > -->
        <ListingTable
          :columnCount.sync="defaultColDefs.length"
          :dataLoading.sync="dataLoading"
          :rowData.sync="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{
                      'checkbox-width-limit': cols.checkbox,
                      'pointer-events-none': lodash.isEmpty(rowData),
                    }"
                  >
                    <template v-if="cols.checkbox">
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort,
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!lodash.isEmpty(rowData)">
                <tr v-for="(data, index) in rowData" :key="index" link>
                  <template v-for="cols in defaultColDefs">
                    <td
                      v-if="cols.visible"
                      :key="cols.id"
                      :class="{
                        'simple-table-td': !cols.checkbox,
                        'checkbox-width-limit': cols.checkbox,
                      }"
                      v-on:click="
                        !cols.checkbox ? rowClicked(data) : 'javascript:void(0)'
                      "
                    >
                      <template v-if="cols.checkbox">
                        <v-checkbox
                          dense
                          v-model="selectedRows"
                          v-bind:value="data.id"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else-if="cols.field == 'barcode'">
                        <Barcode
                          route="project.detail"
                          :id="data.id"
                          :barcode="data.barcode"
                        ></Barcode>
                        <CustomStatus
                          small
                          :status.sync="data.status"
                          endpoint="project/status"
                        ></CustomStatus>
                      </template>
                      <template v-else-if="cols.field == 'soled_job_no'">
                        <div class="project-listing-project">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Ref Job No.: </b> {{ data.soled_job_no }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Client PO No.: </b> {{ data.client_po_no }}
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'name'">
                        <div class="project-listing-project">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Name: </b> {{ data.name }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Reference #: </b> {{ data.reference }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                                  color="cyan"
                                  text-color="white"
                                  label
                                  small
                                >
                                  {{ data.billing_type_text }}
                                </v-chip>
                              </template>
                              <span>Billing Type</span>
                            </v-tooltip>
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'customer'">
                        <div
                          class="project-listing-project"
                          v-if="data.customer"
                        >
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Attention: </b>
                            {{ data.attention }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Display Name: </b>
                            {{ data.customer.display_name }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Company: </b> {{ data.customer.company_name }}
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'budget'">
                        <span
                          v-if="data.total_expenses > data.project_budget"
                          style="color: red"
                          ><b>{{ data.total_expenses }}</b></span
                        >
                        <span v-else style="color: green"
                          ><b>{{ data.total_expenses }}</b></span
                        >
                        / <b>{{ data.project_budget }}</b>
                      </template>
                      <template v-else-if="cols.field == 'tags'">
                        <div v-for="tag in data.tags" :key="tag">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="custom-status d-inline font-weight-600 custom-grey-border text-uppercase justify-center"
                                color="cyan"
                                text-color="white"
                                label
                                small
                              >
                                {{ tag }}
                              </v-chip>
                            </template>
                            <span>Tag</span>
                          </v-tooltip>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'status'">
                        <div class="project-listing-status">
                          <CustomStatus
                            small
                            :status.sync="data.status"
                            endpoint="project/status"
                          ></CustomStatus>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'tasks'">
                        <div class="project-listing-status">
                          {{ data.tasks_count }}
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'dates'">
                        <div class="project-listing-dates">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Started At: </b>
                            {{ formatDate(data.started_at) }}
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Finished At: </b>
                            <template v-if="data.finished_at">{{
                              formatDate(data.finished_at)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >Not Finished Yet</em
                              ></template
                            >
                          </p>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <b>Deadline: </b>
                            <template v-if="data.deadline">{{
                              formatDate(data.deadline)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Deadline</em
                              ></template
                            >
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'added_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.added_by)"
                          :data.sync="data"
                        >
                          <template v-slot:display_name>
                            {{ data.added_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.created_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.added_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else-if="cols.field == 'updated_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.updated_by)"
                          :data.sync="data"
                        >
                          <template v-slot:display_name>
                            {{ data.updated_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.modified_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.updated_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else>
                        <div v-html="printRowData(cols, data)"></div>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td :colspan="defaultColDefs.length" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no project at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
        <!-- </perfect-scrollbar> -->
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Project</template>
          <template v-slot:body>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <template v-for="(field, index) in searchableArray">
                  <v-col md="6" :key="index" v-if="field != 'dates'">
                    <v-text-field
                      v-model.trim="listingSearch[field]"
                      dense
                      filled
                      hide-details
                      :label="getFilterLabel(field, 'Project')"
                      solo
                      flat
                      clearable
                      @keydown.enter="filterRows"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-container>
            <!-- </perfect-scrollbar> -->
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <template v-if="customerDialog">
          <CustomerDialog
            isProject
            :customerDialog.sync="customerDialog"
            v-on:closeDialog="customerDialog = false"
            v-on:resetAll="customerDialog = false"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="getPermission('project:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Project</template>
            <template v-slot:body>
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 60vh; position: relative"
              > -->
              <v-form
                ref="projectImport"
                v-model="valid_import"
                lazy-validation
                v-on:submit.stop.prevent="importRows()"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-btn
                    v-on:click="downloadSample()"
                    :disabled="dataLoading || loading_imported"
                    class="mx-2 custom-grey-border custom-bold-button"
                    color="cyan white--text"
                  >
                    Download Sample
                  </v-btn>
                  <v-file-input
                    :disabled="loading_imported"
                    :rules="[
                      (value) =>
                        !value ||
                        value.size < 5000000 ||
                        'File size should be less than 5 MB!',
                    ]"
                    v-on:change="handleFileUpload"
                    placeholder="Select Excel File"
                    color="cyan"
                    show-size
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ></v-file-input>

                  <div>
                    <ul
                      v-if="success_message.length"
                      class="import-success-message"
                    >
                      <li
                        v-for="(message, index) in success_message"
                        :key="index"
                      >
                        {{ message }}
                      </li>
                    </ul>
                    <ul
                      v-if="error_message.length"
                      class="import-error-message"
                    >
                      <li
                        v-for="(message, index) in error_message"
                        :key="index"
                      >
                        {{ message }}
                      </li>
                    </ul>
                    <p
                      v-if="error_message.length"
                      class="m-0 font-size-18 font-weight-700 red--text text--lighten-1"
                    >
                      Please check excel file.
                    </p>
                  </div>
                </v-container>
              </v-form>
              <!-- </perfect-scrollbar> -->
            </template>
            <template v-slot:action>
              <v-btn
                v-if="!excel_imported"
                :disabled="dataLoading || !valid_import || loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows()"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Project Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, POST } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import JwtService from "@/core/services/jwt.service";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "project-list",
  data() {
    return {
      pageTips: false,
      customerDialog: false,
      exportLoading: false,
      pageModule: "project-listing",
      routeAPI: "projects",
      routeName: "project",
      routeDetailName: "project.detail",
      status: "all",
      file: "",
      importDialog: false,
      excel_imported: false,
      valid_import: true,
      loading_imported: false,
      error_message: [],
      success_message: [],
      statusList: [],
      moreActions: [
        {
          title: "Import Project(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        {
          title: "Export Project(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      projectMoreAction: [
        {
          title: "Mark as Finished",
          action: "mark_as_finish",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Cancelled",
          action: "mark_as_cancel",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as Hold",
          action: "mark_as_hold",
          icon: "mdi-check-all",
        },
        {
          title: "Mark as In-Progress",
          action: "mark_as_inprogress",
          icon: "mdi-check-all",
        },
        /*{ title: "Delete", action: "delete", icon: "mdi-delete" },
        { title: "Download PDF", action: "download_pdf", icon: "mdi-file-pdf" },
        { title: "Print", action: "print", icon: "mdi-printer" }*/
      ],
    };
  },
  components: {
    draggable,
    CustomerDialog,
    PageTips,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomStatus,
  },
  methods: {
    downloadSample() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "project/sample-download?token=" + token;
      window.open(url, "_target");
    },
    projectExport() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "project/export?token=" + token;
      window.open(url, "_target");
    },
    handleFileUpload(param) {
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },
    importRows() {
      const _this = this;
      const file = _this.file;
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      _this.loading_imported = true;
      _this.excel_imported = false;
      _this.success_message = [];
      _this.error_message = [];
      data.append("excel", file);
      _this.$store
        .dispatch(POST, {
          url: "project/import",
          data: data,
        })
        .then(({ data }) => {
          _this.success_message = data.success || [];
          _this.error_message = data.error || [];
          _this.excel_imported = true;
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loading_imported = false;
        });
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "mark_as_finish":
          _this.updateProjectStatus(5);
          break;
        case "mark_as_cancel":
          _this.updateProjectStatus(4);
          break;
        case "mark_as_hold":
          _this.updateProjectStatus(3);
          break;
        case "mark_as_inprogress":
          _this.updateProjectStatus(2);
          break;
      }
    },
    updateProjectStatus(status) {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "project/status",
          data: { project: _this.selectedRows, status },
        })
        .then(() => {
          _this.getRows();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    selectCustomer(customer) {
      if (customer > 0) {
        this.$router.push(
          this.getDefaultRoute("project.create", {
            query: { customer },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.projectExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("project");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Project",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 1,
      },
      {
        headerName: "Project #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
      },
      /* {
        headerName: "Ref Job No. / Client PO No.",
        field: "soled_job_no",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 2,
      }, */
      {
        headerName: "Project",
        field: "name",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
      },
      {
        headerName: "Customer Info",
        field: "customer",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      },
      {
        headerName: "Budget",
        field: "budget",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      },
      {
        headerName: "Tags",
        field: "tags",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
      },
      /* {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
      }, */
      {
        headerName: "Dates",
        field: "dates",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
      },
      {
        headerName: "Tasks",
        field: "tasks",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 7,
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 8,
      },
    ];

    _this.defaultColShow = _this.defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
};
</script>
